'use strict';
var toastr = require('toastr');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} $elm - element clicked
 */
function displayMessageAndChangeIcon(data, $elm) {
    $.spinner().stop();
    $elm.toggleClass('active');
    toastr.remove();
    if (data.success) {
        toastr.success(data.msg);
    } else {
        toastr.error(data.msg);
    }
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var $elm = $(this);
            var url = $(this).attr('href');
            var pid = $(this).closest('.product').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }
            $.spinner().start();
            // logic to remove the product from wishlist
            if ($elm.hasClass('active')) {
                url = $(this).attr('data-remove-url');
                url += '?pid=' + pid;
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {},
                    success: function (data) {
                        displayMessageAndChangeIcon(data, $elm);
                    },
                    error: function (err) {
                        displayMessageAndChangeIcon(err, $elm);
                    }
                });
            } else {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal
                    },
                    success: function (data) {
                        displayMessageAndChangeIcon(data, $elm);
                    },
                    error: function (err) {
                        displayMessageAndChangeIcon(err, $elm);
                    }
                });
            }
        });
    }
};
